import React, { Component } from "react";

class About extends Component{
    render(){
        let title = 'Who We Are',
        description = 'Established in 2014, PT Citra Wira Bawana is an Indonesian based company that specializes in providing high quality medical kits and supplies. We pledge to be consistent and competent in continuously delivering best products and services as our solutions to the customers.',
        // about list title-mission
        title1 = 'Mission',
        description1 = 'To become your trustwort partner to the world with innovative technologies, reliable quality and professional solutions.',
        // about list-vision
        title2='Vision',
        description2='We commit to become Indonesia’s best healthcare provider on quality medical kits and supplies that delivers best solutions to our customers and maintains our consistent standards. '
        return(
            <React.Fragment>
                <div className="about-wrapper">
                    <div className="container">
                        <div className="row row--35 align-items-center">

                            <div className="col-lg-5 col-md-12">
                                <div className="thumbnail">
                                    <img className="w-100" src="/assets/images/about/aboutUs.png" alt="About"/>
                                </div>
                            </div>

                            <div className="col-lg-7 col-md-12">
                                <div className="about-inner inner">
                                    <div className="section-title">
                                        <h2 className="title">{title}</h2>
                                        <p className="description">{description}</p>
                                    </div>
                                    <div className="row mt--30 mt_sm--10">
                                        <div className="col-lg-6 col-md-12 col-sm-12 col-12">
                                            <div className="about-us-list">
                                                <h3 className="title">{title1}</h3>
                                                <p>{description1}</p>
                                            </div>
                                        </div>
                                        <div className="col-lg-6 col-md-12 col-sm-12 col-12">
                                            <div className="about-us-list">
                                                <h3 className="title">{title2}</h3>
                                                <p>{description2}</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </React.Fragment>
        )
    }
}
export default About;
