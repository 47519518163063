import React, { Component } from "react";
import {GiHospital,GiVibratingShield,GiStethoscope,GiMonkeyWrench} from "react-icons/gi";
const ServiceList = [
    {
        icon: <GiVibratingShield />,
        title: 'Personal Protective Equipment (PPE)',
        description: 'This reusable water-resistant suit provides full-body coverage as an effective protection to prevent the body infected with viruses, bacteria, or other airborne particles'
    },
    {
        icon: <GiStethoscope />,
        title: 'Medical Equipment',
        description: 'Wide range of medical equipment including PPE, isolation chambers etc.'
    },
    {
        icon: <GiHospital />,
        title: 'Laboratory Construction',
        description: 'Proven track record in lab constructions around Indonesia'
    },
    {
        icon: <GiMonkeyWrench />,
        title: 'Maintenance and Repair',
        description: 'Medical maintenance and repair services'
    }
]

class Service extends Component{
    render(){
        let title = 'Product and Services',
        description = 'Find out more about all services we provide to help our clients find best solutions for quality medical kits and supplies.';
        return(
            <React.Fragment>
                <div className="row">
                    <div className="col-lg-4 col-12">
                        <div className="section-title mt--30 mt_md--5 mt_mobile--5 mb_mobile--10">
                            <h2 className="title">{title}</h2>
                            <p>{description}</p>
                            <div className="service-btn">
                                <a className="btn-transparent rn-btn-dark" href="/contact"><span className="text">Request Custom Service</span></a>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-8 col-12 mt_md--50">
                        <div className="row service-one-wrapper">
                            {ServiceList.map( (val , i) => (
                                <div className="col-12" key={i}>
                                <div className="service service__style--2">
                                    <div className="icon">
                                        {val.icon}
                                    </div>
                                    <div className="content">
                                        <h3 className="title">{val.title}</h3>
                                        <p>{val.description}</p>
                                    </div>
                                </div>
                                </div>
                            ))}
                        </div>
                    </div>
                </div>
            </React.Fragment>
        )
    }
}
export default Service;
