// React Required
import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import {
  GoogleReCaptchaProvider
} from 'react-google-recaptcha-v3';

// Create Import File
import './index.scss';

// Common Layout
// import Layout from "./component/common/App";

// Home layout
import Home from './home';

// Element Layout
import About from "./elements/About";
import Contact from "./elements/Contact";
import PortfolioDetails from "./elements/PortfolioDetails";
import error404 from "./elements/error404";

import { BrowserRouter, Switch, Route  } from 'react-router-dom';
import * as serviceWorker from './serviceWorker';

class Root extends Component{
    render(){
        return(
            <BrowserRouter basename={'/'}>
                <Switch>
                    <Route exact path={`${process.env.PUBLIC_URL}/`} component={Home}/>

                    {/* Element Layot */}
                    <Route exact path={`${process.env.PUBLIC_URL}/contact`} component={Contact}/>
                    <Route exact path={`${process.env.PUBLIC_URL}/about`} component={About}/>
                    <Route exact path={`${process.env.PUBLIC_URL}/project/:id`} component={PortfolioDetails}/>

                    <Route path={`${process.env.PUBLIC_URL}/404`} component={error404}/>
                    <Route component={error404}/>

                </Switch>
            </BrowserRouter>
        )
    }
}

ReactDOM.render(
  <GoogleReCaptchaProvider reCaptchaKey="6LeUbMwZAAAAACyptFagn4oz8Fdj9hFXIHiPfAj6">
    <Root/>
  </GoogleReCaptchaProvider>,
  document.getElementById('root')
);
serviceWorker.register();
