import React, { Component } from "react";
import PageHelmet from "../component/common/Helmet";
import ScrollToTop from 'react-scroll-up';
import { FiChevronUp } from "react-icons/fi";
import Header from "../component/header/Header";
import Footer from "../component/footer/Footer";

const PortfolioList = {
  'kramatjati': {
    'title': 'BSL 2 Laboratory for RS Polri Jakarta',
    'description': 'in the fight to protect laboratory analyst from the infectious COVID-19 diagnosis. Built for the 2020 pandemic, the lab is constructed and equipped with the latest technologies for virus RNA extraction and PCR diagnosis. The facility features clean and sterile spaces for reagent storage, RNA extraction, PCR diagnosis, and autoclave room complemented with interlocking doors, clean pass-through chambers, anteroom, and UPS. It was mandatory for the lab to be 24/7 operational during the pandemic to ensure fast and reliable results. CWB managed to successfully deliver the project while mitigating the risk of contamination and interference with the building occupants of critical production activities.',

    'titlebranch': 'Project Type',
    'titleproject': 'Lab Construction',

  },
  'surabaya': {
    'title': 'BSL 2 Laboratory for RS Polri Surabaya',
    'description': 'in the fight to protect laboratory analyst from the infectious COVID-19 diagnosis. Built for the 2020 pandemic, the lab is constructed and equipped with the latest technologies for virus RNA extraction and PCR diagnosis. The facility features clean and sterile spaces for reagent storage, RNA extraction, PCR diagnosis, and autoclave room complemented with interlocking doors, clean pass-through chambers, anteroom, and UPS. It was mandatory for the lab to be 24/7 operational during the pandemic to ensure fast and reliable results. CWB managed to successfully deliver the project while mitigating the risk of contamination and interference with the building occupants of critical production activities.',

    'titlebranch': 'Project Type',
    'titleproject': 'Lab Construction',
  }
}

class PortfolioDetails extends Component{
    constructor (){
        super()
        this.state = {
          isOpen: false
        }
        this.openModal = this.openModal.bind(this)
    }
    openModal (){
        this.setState({isOpen: true})
    }

    render(){
        const models = this.props.match.params.id || 'surabaya'

        return(
            <React.Fragment>
                <PageHelmet pageTitle='Portfolio Details' />

                <Header headertransparent="header--transparent" colorblack="color--black" logoname="logo.png" />

                {/* Start Breadcrump Area */}
                <div className="rn-page-title-area ptb--120 pb--90 bg_image bg_image--21"  data-black-overlay="1">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="rn-page-title text-center pt--100">
                                 <h2 className="title theme-gradient">{PortfolioList[models].title}</h2>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* End Breadcrump Area */}

                {/* Start Portfolio Details */}
                <div className="rn-portfolio-details ptb--120 bg_color--1">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="portfolio-details">
                                    <div className="inner">
                                        <p>{PortfolioList[models].description}</p>

                                            <div className="portfolio-view-list d-flex flex-wrap">
                                            <div className="port-view">
                                                <span>{PortfolioList[models].titlebranch}</span>
                                                <h4>{PortfolioList[models].titleproject}</h4>
                                            </div>

                                        </div>
                                    </div>

                                    <div className="portfolio-thumb-inner" >
                                        <div className="thumb position-relative mb--30">
                                            <img src={`/assets/images/portfolio/${[models]}/image-1.jpg`} alt="Portfolio Images" className="image-video" />
                                        </div>

                                        <div className="thumb mb--30">
                                            <img src={`/assets/images/portfolio/${[models]}/image-2.jpg`} alt="Portfolio Images"/>
                                        </div>

                                        <div className="thumb item-3">
                                            <img src={`/assets/images/portfolio/${[models]}/image-1.jpg`} alt="Portfolio Images"/>
                                        </div>
                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                {/* End Portfolio Details */}

                {/* Start Related Work */}
                {
                  /*
                  <div className="portfolio-related-work pb--120 bg_color--1">
                      <div className="container">
                          <div className="row">
                              <div className="col-lg-12">
                                  <div className="section-title text-center">
                                      <span className="theme-color font--18 fontWeight600">{PortfolioList[models].titleRealeted}</span>
                                      <h2>{PortfolioList[models].titleOther}</h2>
                                  </div>
                              </div>
                          </div>
                          {PortfolioList[models].titlebody &&(
                          <div className="row mt--10">
                              <div className="col-lg-6 col-md-6 col-12">
                                  <div className="related-work text-center mt--30">
                                      <div className="thumb">
                                          <a href="/portfolio-details">
                                              <img src="/assets/images/portfolio/portfolio-4.jpg" alt="Portfolio-images"/>
                                          </a>
                                      </div>
                                      <div className="inner">
                                          <h4><a href="/portfolio-details">{[models]}</a></h4>
                                          <span className="category">{[models]}</span>
                                      </div>
                                  </div>
                              </div>
                              <div className="col-lg-6 col-md-6 col-12">
                                  <div className="related-work text-center mt--30">
                                      <div className="thumb">
                                          <a href="/portfolio-details">
                                              <img src="/assets/images/portfolio/portfolio-2.jpg" alt="Portfolio-images"/>
                                          </a>
                                      </div>
                                      <div className="inner">
                                          <h4><a href="/portfolio-details">{PortfolioList[models].details2}</a></h4>
                                          <span className="category">{[models]}</span>
                                      </div>
                                  </div>
                              </div>
                               </div>
                               )}

                               {PortfolioList[models].id && (
                               <Accordion01 />
                               )}

                          </div>
                   </div>
                   */
                }
                {/* End Related Work */}

                {/* Start Back To Top */}
                <div className="backto-top">
                    <ScrollToTop showUnder={160}>
                        <FiChevronUp />
                    </ScrollToTop>
                </div>
                {/* End Back To Top */}
                <Footer />
        </React.Fragment>
        )
    }
}
export default PortfolioDetails;
