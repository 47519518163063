import React, { useState } from "react";
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { useGoogleReCaptcha } from 'react-google-recaptcha-v3';
import axios from "axios";
import ClipLoader from "react-spinners/ClipLoader";

const Contact = () => {
    const { executeRecaptcha } = useGoogleReCaptcha();

    const [serverState, setServerState] = useState();
    const handleServerResponse = (ok, msg) => {
      setServerState({ok, msg});
    };

    const formik = useFormik({
      initialValues: {
        name: '',
        email: '',
        phone: '',
        subject: '',
        message: '',
        recaptcha: ''
      },
      validationSchema: Yup.object({
        name: Yup.string()
          .min(6, 'Must be 6 characters or more')
          .required('Required'),
        email: Yup.string()
          .email('Invalid email address')
          .required('Required'),
        phone: Yup.string()
          .min(8, 'Invalid phone number')
          .required('Required'),
        subject: Yup.string()
          .min(6, 'Must be 6 characters or more')
          .required('Required'),
        message: Yup.string()
          .min(25, 'Must be 25 characters or more')
          .required('Required')
      }),
      onSubmit: (values, actions) => {
        actions.setSubmitting(true);

        if (!executeRecaptcha) {
          actions.setSubmitting(false);
          handleServerResponse(false, "Error loading recaptcha");
          return false;
        }

        executeRecaptcha("contact").then(recaptcha => {
          values.recaptcha = recaptcha

          axios({
            method: "POST",
            url: "/send",
            data: values
          })
            .then(response => {
              actions.setSubmitting(false);
              actions.resetForm();
              handleServerResponse(true, "Thank you for your message, we will get back to you soon");
            })
            .catch(error => {
              actions.setSubmitting(false);
              console.log(error.response)
              handleServerResponse(false, "Error sending message"); //error.response.data.error);
            });
        }).catch(error => {
          actions.setSubmitting(false);
          console.log(error.response)
          handleServerResponse(false, "Error sending message (recaptcha)"); //error.response.data.error);
        })
      },
    });

    return(
          <div className="contact-form--1">
              <div className="container">
                  <div className="row row--35 align-items-start">
                      <div className="col-lg-6 order-2 order-lg-1">
                          <div className="section-title text-left mb--50">
                              <h2 className="title">Contact Us.</h2>
                              <p className="description">Let’s get in touch! We’d love to hear from you.</p>
                          </div>
                          <div className="form-wrapper">
                            <form onSubmit={formik.handleSubmit}>
                              <label htmlFor="item01">
                                  <input
                                      type="text"
                                      name="name"
                                      id="item01"
                                      onChange={formik.handleChange}
                                      value={formik.values.name}
                                      onBlur={formik.handleBlur}
                                      placeholder="Your Name *"
                                  />
                                {formik.touched.name && formik.errors.name ? <div className="error">{formik.errors.name}</div> : null}
                              </label>

                              <label htmlFor="item02">
                                  <input
                                      type="text"
                                      name="email"
                                      id="item02"
                                      onChange={formik.handleChange}
                                      value={formik.values.email}
                                      onBlur={formik.handleBlur}
                                      placeholder="Your email *"
                                  />
                                {formik.touched.email && formik.errors.email ? <div className="error">{formik.errors.email}</div> : null}
                              </label>

                              <label htmlFor="item03">
                                  <input
                                      type="text"
                                      name="phone"
                                      id="item03"
                                      onChange={formik.handleChange}
                                      value={formik.values.phone}
                                      onBlur={formik.handleBlur}
                                      placeholder="Your Phone Number *"
                                  />
                                {formik.touched.phone && formik.errors.phone ? <div className="error">{formik.errors.phone}</div> : null}
                              </label>

                              <label htmlFor="item04">
                                  <input
                                      type="text"
                                      name="subject"
                                      id="item04"
                                      onChange={formik.handleChange}
                                      value={formik.values.subject}
                                      onBlur={formik.handleBlur}
                                      placeholder="Write a Subject"
                                  />
                                {formik.touched.subject && formik.errors.subject ? <div className="error">{formik.errors.subject}</div> : null}
                              </label>
                              <label htmlFor="item05">
                                  <textarea
                                      type="text"
                                      id="item05"
                                      name="message"
                                      onChange={formik.handleChange}
                                      value={formik.values.message}
                                      onBlur={formik.handleBlur}
                                      placeholder="Your Message"
                                  />
                                {formik.touched.message && formik.errors.message ? <div className="error">{formik.errors.message}</div> : null}
                              </label>
                              <button className="rn-button-style--2 btn-solid" type="submit" value="submit" name="submit" disabled={formik.isSubmitting}>
                                {
                                  formik.isSubmitting
                                    ?
                                    <ClipLoader
                                      size={28}
                                      color={"#fff"}
                                    />
                                  :
                                    <>Submit</>
                                }
                              </button>

                              {serverState && (
                                <p className={!serverState.ok ? "error ptb-10" : "ptb-10"}>
                                  {serverState.msg}
                                </p>
                              )}
                            </form>
                          </div>
                      </div>
                      <div className="col-lg-6 order-1 order-lg-2">
                          <div className="thumbnail mb_md--30 mb_sm--30">
                              <img src="/assets/images/about/overlayColor.png" alt="CWB"/>
                          </div>
                      </div>
                  </div>
              </div>
          </div>
        )
}
export default Contact;
