import React, { Component } from "react";
import PageHelmet from "../component/common/Helmet";
import Breadcrumb from "../elements/common/Breadcrumb";
import ScrollToTop from 'react-scroll-up';
import { FiChevronUp } from "react-icons/fi";
import Header from "../component/header/Header";
import Footer from "../component/footer/Footer";


class About extends Component{
    render(){
        let title = 'Who We Are',
        description = 'Established in 2014, PT Citra Wira Bawana is a certified Indonesian medical supplies company/contractor that specalizes in providing high quallity medical kits and supplies. We pledge to be consistent and competent in continuously delivering best products and services as our solutions to the customers.',
        secondDescription='Headquartered in Jakarta, Indonesia with over 5 years of experience. We focus on maintaining top-quality supplies from top manufacturers, so we can ensure that we help to serve the best needs for Indonesia\'s healthcare system. CWB strives to quickly respond to capricious market and make our services as Indonesia\'s go-to, most trusted resources for an ymedical kits and supplies\' needs.',
        // about list title-mission
        title1 = 'Mission',
        description1 = 'To become your trustworthy partner to the world with innovative technologies, reliable quality and professional solutions.',
        // about list-vision
        title2='Vision',
        description2='We commit to become Indonesia\'s best healthcare provider on quality medical kits and supplies that delivers best solutions to our customers and maintains our consistent standards.',
        //Be Part
        content ='BE PART OF OUR TEAM',
        description3 = 'CWB Medical is always looking for qualified individuals who can bring out ideas and passion to make a difference.',
        //team
        titleTeam = 'Our Team',
        description4 = 'Meet the minds and hearts of CWB Medical, our dedicated team who is here to make the biggest impact possible.'
        return(
            <React.Fragment>
                <PageHelmet pageTitle='About' />
                <Header headertransparent="header--transparent" colorblack="color--black" logoname="logo.png" />
                {/* Start Breadcrump Area */}
                <Breadcrumb title={'About'}   />
                {/* End Breadcrump Area */}

                {/* Start About Area  */}
                <div className="rn-about-area ptb--120 bg_color--1">
                    <div className="rn-about-wrapper">
                        <div className="container">
                            <div className="row row--35 align-items-center">
                                <div className="col-lg-5">
                                    <div className="thumbnail">
                                        <img className="w-100" src="/assets/images/about/aboutUs.png" alt="About"/>
                                    </div>
                                </div>
                                <div className="col-lg-7">
                                    <div className="about-inner inner">
                                        <div className="section-title">
                                            <h2 className="title">{title}</h2>
                                            <p className="description">{description}</p>
                                            <p className="description">{secondDescription}</p>
                                        </div>
                                        <div className="row mt--30">
                                            <div className="col-lg-6 col-md-12 col-sm-12 col-12">
                                                <div className="about-us-list">
                                                    <h3 className="title">{title1}</h3>
                                                        <p>{description1}</p>
                                                </div>
                                            </div>
                                            <div className="col-lg-6 col-md-12 col-sm-12 col-12">
                                                <div className="about-us-list">
                                                    <h3 className="title">{title2}</h3>
                                                    <p>{description2}</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* End About Area  */}

                {/* Start Finding Us Area  */}
                <div className="rn-finding-us-area rn-finding-us bg_color--1">
                    <div className="inner">
                        <div className="content-wrapper">
                            <div className="content">
                                <h4 className="theme-gradient">{content}</h4>
                                <p>{description3}</p>
                                <a className="rn-btn btn-white" href="/contact">Join Us</a>
                            </div>
                        </div>
                        <div className="thumbnail">
                            <div className="image">
                                <img src="/assets/images/about/career.png" alt="Career"/>
                            </div>
                        </div>
                    </div>
                </div>
                {/* End Finding Us Area  */}

                {/* Start Team Area  */}
                {/* <div className="rn-team-area bg_color--1 ptb--120">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="section-title service-style--3 text-center mb--25">
                                    <h2 className="title">{titleTeam}</h2>
                                    <p>{description4}</p>
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-lg-4 col-md-6 col-sm-6 col-12">
                                <div className="team">
                                    <div className="thumbnail">
                                        <img className="w-100" src="/assets/images/team/hillary.jpg" alt="Hillary"/>
                                    </div>
                                    <div className="content">
                                        <h4 className="title">Hillary Luwena</h4>
                                        <p className="designation">Director</p>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-4 col-md-6 col-sm-6 col-12">
                                <div className="team">
                                    <div className="thumbnail">
                                        <img className="w-100" src="/assets/images/team/sumardjo.jpg" alt="Sumardjo"/>
                                    </div>
                                    <div className="content">
                                        <h4 className="title">Sumardjo</h4>
                                        <p className="designation">Director</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div> */}
                {/* End Team Area  */}

                {/* Start Back To Top */}
                <div className="backto-top">
                    <ScrollToTop showUnder={160}>
                        <FiChevronUp />
                    </ScrollToTop>
                </div>
                {/* End Back To Top */}

                <Footer />

            </React.Fragment>
        )
    }
}
export default About
