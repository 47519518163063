import React, { Component } from "react";
import Slider from "react-slick";

const portfolioSlick = {
    infinite: true,
    slidesToShow: 5,
    slidesToScroll: 1,
    dots: true,
    arrows: true,
    responsive: [{
        breakpoint: 800,
        settings: {
            slidesToShow: 3,
        }
    },
    {
        breakpoint: 1200,
        settings: {
            slidesToShow: 3,
        }
    },
    {
        breakpoint: 993,
        settings: {
            slidesToShow: 2,
        }
    },
    {
        breakpoint: 769,
        settings: {
            slidesToShow: 2,
        }
    },
    {
        breakpoint: 481,
        settings: {
            slidesToShow: 1,
        }
    }
]
};

const PortfolioList = [
    {
        id: 'kramatjati',
        image: 'image-1',
        category: 'Jakarta',
        title: 'BSL-2 Laboratory, Kramat Jati Hospital'
    },
    {
        image: 'image-2',
        category: 'Depok',
        title: 'BSL-2 Laboratory, Brimob Hospital'
    },
    {
        id: 'surabaya',
        image: 'image-3',
        category: 'Surabaya',
        title: 'BSL-2 Laboratory, Bhayangkara Hospital'
    },
    {
        image: 'image-4',
        category: 'Makassar',
        title: 'BSL-2 Laboratory, Bhayangkara Hospital'
    },
    {
        image: 'image-3',
        category: 'Palembang',
        title: 'BSL-2 Laboratory, Bhayangkara Hospital'
    },
    {
        image: 'image-4',
        category: 'Jakarta',
        title: 'COVID-19 PCR Test devices, Police hospital'
    },
    {
        id:'faq',
        image: 'image-3',
        category: 'Software & FAQ',
        title: 'Description about the software and a faq section'
    },
]

class Portfolio extends Component{
    render(){
        let title = 'Our Projects',
        description = 'Learn how we deliver the quality of work we produce by taking a step on to our current projects.';
        return(
            <React.Fragment>
                <div className="portfolio-wrapper">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-6">
                                <div className="section-title">
                                    <h2>{title}</h2>
                                    <p>{description}</p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="portfolio-slick-activation mt--70 mt_sm--40">
                        <Slider {...portfolioSlick}>
                            {PortfolioList.map((value , index) => (
                                <div className="portfolio" key={index}>
                                    <div className="thumbnail-inner">
                                        <div className={`thumbnail ${value.image}`}></div>
                                        <div className={`bg-blr-image ${value.image}`}></div>
                                    </div>
                                    <div className="content">
                                        <div className="inner">
                                            <p>{value.category}</p>
                                            <h4><a href="/portfolio/">{value.title}</a></h4>
                                            {
                                              value.id && (
                                                <div className="portfolio-button">
                                                    <a className="rn-btn" href={`/project/${value.id}`}>Case Study</a>
                                                </div>
                                              )
                                            }
                                        </div>
                                    </div>
                                </div>
                            ))}
                        </Slider>
                    </div>
                </div>
            </React.Fragment>
        )
    }
}
export default Portfolio;
